import { createRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  NavLink,
  useLocation,
  useOutlet,
} from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Home from './components/home/home';
import Simulatore from './components/simulatore/simulatore';
import Wrapping from './components/wrapping/wrapping';
import Contact from './components/contatti/contatti';
import Vendita from './components/vendita/vendita';
import Rental from './components/noleggio/noleggio';
import Footer from './components/functional/footer/footer';
import './RS.css';


// scelta multilingua redux
// 

const routes = [
  { path: '/', name: 'HOME', element: <Home />, nodeRef: createRef()},
  { path: '/vendita',name: 'VENDITA',element: <Vendita />,nodeRef: createRef()},
  { path: '/noleggio',name: 'NOLEGGIO SUPERCARS',element: <Rental />,nodeRef: createRef()},
  { path: '/simulazione',name: 'SIMULAZIONE',element: <Simulatore />,nodeRef: createRef()},
  { path: '/wrapping', name: 'WRAPPING', element: <Wrapping />, nodeRef: createRef()},
  { path: '/contatti',name: 'CONTATTI',element: <Contact />,nodeRef: createRef()},
]



const RS =()=> {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {}
  const [navNode, setNavNode] = useState('MENU');

  const nav = () =>{
    if(navNode==='MENU'){
      setNavNode('CLOSE');
    }else{
      setNavNode('MENU');
    }
  }
  return (
    <div id='scroller' className='fullDev column' style={{overflowY:'scroll',overflowX:'hidden',fontFamily:'Gemunu Libre',letterSpacing:'0.05vw'}}>


     <div  className='absolute column' style={{top:'0'}}>
        <div id='anchor'></div>
        {
          window.innerHeight<=window.innerWidth?
            <div className="row" style={{width:'90vw',height:'5vw',justifyContent:'space-between',fontWeight:'400',fontSize:'1.3vw',letterSpacing:'0.1vw'}}>

              {routes.map((route) => (
                <NavLink
                  key={route.path}
                  to={route.path}
                  className={({ isActive }) => (isActive ? 'active' : 'unactive')}
                  end
                >
                  {route.name}
                </NavLink>
              ))}
            </div>
          :
            <div id='mobNav' className='column' style={{background:'none',width:'40vw',height:'40vh',padding:'5vw',position:'fixed',bottom:'5vw',right:'0',zIndex:'3',transform:navNode==='MENU'?'translateX(60vw)':'translateX(0)',transition:'200ms',alignItems:'flex-end'}}>
              <div className='absolute' style={{background:'#ff2c02cc',width:'50vw',height:'50vh',left:'0',transform:'skew(-20deg) translateX(10vw)',zIndex:'0'}}></div>
              
              <div onClick={nav}
               className='absolute' style={{bottom:'0',left:'-27vw',background:'#ff2c02cc',transform:'skew(-20deg)'}}>
                <p style={{padding:'0 5vw',fontSize:'4vw',transform:'skew(20deg)',fontWeight:'700',margin:'3vw 0'}}>{navNode}</p>
              </div>

              {routes.map((route) => (
                <NavLink
                  onClick={nav}
                  key={route.path}
                  to={route.path}
                  className={({ isActive }) => (isActive ? 'mobActive' : 'mobUnactive')}
                  end
                >
                  {route.name}
                </NavLink>
              ))}
          
            </div>
        }
        
  
      <div>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={300}
            classNames="page"
            unmountOnExit
          >
            {(state) => (
              <div ref={nodeRef} className="page">
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      <Footer />
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RS />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<RouterProvider router={router} />);

