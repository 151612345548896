import React from 'react';
import Header from '../../functional/deskHeader-mobile';
import ImgDecoratorMob from '../../functional/fullImage-mobile';
import due from '../../../assets/wrap_2.jpg';
import tre from '../../../assets/wrap_3.jpg';
import Whats from '../../functional/customWhats';
const Mobile =()=> {

    
    return (
        <div className='relative column' style={{overflow:'hidden',width:'100vw',}}>
           <Header text={'WRAPPING'}/>
           <video style={{width:'110vw',height:'100vw',objectFit:'cover',margin:'0'}}
               crossOrigin="*"
               autoPlay
               muted
               loop>
                <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rs_wrapping.mp4' type="video/mp4" />

        </video>
  
  
    
  
        <div className='column' style={{width:'80vw',margin:'3vw 0 0 0',justifyContent:'space-between',color:'#d9d9d9'}}>
        <div className='column' style={{width:'80vw',alignItems:'flex-start',margin:'4vw 0 0 0'}}>
            <p style={{fontSize:'5.5vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>RS CUSTOM WRAPPING</p>
            <p style={{fontSize:'5vw',margin:'2vw 0 0 0'}}>Vuoi cambiare look alla tua vettura, donandole un nuovo colore, un nuovo stile? Il Wrapping grazie a pellicole all’avanguardia ti permette di personalizzare il tuo veicolo.</p>
        </div>
        <div className='column' style={{width:'80vw',alignItems:'flex-start',margin:'4vw 0 0 0'}}>
            <p style={{fontSize:'5.5vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>PAINT PROTECTION FILM</p>
            <p style={{fontSize:'5vw',margin:'2vw 0 0 0'}}>Il PPF ovvero il Paint Protection Film, è una pellicola trasparente che protegge la vernice e i fanali da graffi, scheggiature e abrasioni.</p>
        </div>
      </div>
      <Whats svgStyle={{width:'15vw',fill:'#25d366',margin:'0 1vw 0 2.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'5vw',fontWeight:'700',margin:'0 2.5vw 0 0'}}
             parHeight={'5vw'}
             mainMargin={'8vw 0 1vw 0'} />
           

             <ImgDecoratorMob 
        imageUrl={due} 
        imgStyle={{objectFit:'cover',width:'100vw',transform:'skewY(20deg)'}}
        image2Url={tre} 
        img2Style={{objectFit:'cover',width:'100vw',transform:'skewY(20deg)'}}
        />

<div className='column' style={{width:'80vw',justifyContent:'space-between',color:'#d9d9d9'}}>
        <div className='column' style={{width:'80vw',alignItems:'flex-start',margin:'-4vw 0 0 0'}}>
            <p style={{fontSize:'5.5vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>OSCURAMENTO VETRI E FANALI</p>
            <p style={{fontSize:'5vw',margin:'2vw 0 0 0'}}>Utilizziamo pellicole di alta qualità con la
tecnologia della nano ceramica, che oltre
a dare una maggiore privacy respinge
il calore in modo molto efficace.</p>
        </div>
        <div className='column' style={{width:'80vw',alignItems:'flex-start',margin:'4vw 0 0 0'}}>
            <p style={{fontSize:'5.5vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>DETAILING</p>
            <p style={{fontSize:'5vw',margin:'2vw 0 0 0'}}>Pulizia e cura dell’auto, per migliorarla
esteticamente con le dovute attenzioni
e con i giusti prodotti.
Lucidatura - Decontaminazione - Lavaggio e Asciugatura - Pulizia Tappezzeria</p>
        </div>
      </div>
        </div>

    );
  }

export default Mobile;