import React from 'react';
import Rs from '../svg/rs';

const deskHeader = props => {
  return (
    <div className='row' style={{width:'90vw',justifyContent:'flex-start',alignItems:'baseline'}}>
        <div className='row' style={{alignItems:'baseline',transform:'skewX(0)'}}>
            <Rs svgStyle={{width:'6vw',fill:'#ff2c02',zIndex:'2'}} />
            <h1 className='deskH1 relative' style={{left:'-3vw'}}><span style={{color:'#000'}}>RS</span>{props.text}</h1>
        </div>
       
    </div>
  )
}

export default deskHeader