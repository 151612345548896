import React from 'react';
import Header from '../../functional/deskHeader';
import Description from '../../functional/deskription';
import FullDoubleImage from '../../functional/fullDoubleImage';
import zero from '../../../assets/rent_0.jpg';
import one from '../../../assets/rent_1.jpg';
import two from '../../../assets/rent_2.jpg';
import Services from '../../functional/pointServices_desk';
import Whats from '../../functional/customWhats';
const Desktop =()=> {
  
  
  return (
    
    <div className='relative column' style={{width:'90vw',color:'#d9d9d9'}}>
      <Header text={' RENTALS'}/>
      <video style={{width:'100vw',height:'40vw',objectFit:'cover',margin:'0'}}
             crossOrigin="*"
             autoPlay
             muted
             loop>
              <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rent.mp4' type="video/mp4" />

      </video>
      <Description 
       w={'58vw'}
       parHeight={'11vw'}
       align={'justify'}
       title={'Professionisti della guida sportiva al tuo fianco per la tua auto a noleggio.'}
       text={` RS MOTORS offre servizi di noleggio a breve e lungo termine per tutte le categorie di auto su tutto il territorio nazionale. Dalla supercar al SUV fino alla citycar, il nostro parco macchine è in grado di soddisfare qualsiasi esigenza.`}
       />
      <Services serviceNames={[
              'noleggio supercars',
              'noleggio lungo termine',
              'noleggio con conducente',
              'staff operativo 24/7',
              'consegna su tutto il territorio nazionale',
              'noleggio per eventi speciali, film, matrimoni']}  />

      <Whats svgStyle={{width:'3.5vw',fill:'#25d366',margin:'0 1vw 0 1.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'1.3vw',fontWeight:'700',margin:'0 1.5vw 0 0'}}
             parHeight={'1.3vw'}
             mainMargin={'4vw 0 1vw 0'} />
      <FullDoubleImage 
          imageUrl={zero} 
          imgStyle={{width:'50vw',height:'25vw',objectFit:'cover',transform:'skew(20deg) translate(5.5vw,0)'}}
          image2Url={one} 
          img2Style={{height:'40vw',objectFit:'cover',transform:'skew(20deg) translateX(0)'}}
          image3Url={two} 
          img3Style={{height:'35vw',objectFit:'cover',transform:'skew(20deg) translate(-5vw,0)'}}
      />
      
    </div>
            
  
    
  )
}

export default Desktop;

