import React from 'react';

import Header from '../../functional/deskHeader';
import Description from '../../functional/deskription';
import FullDoubleImage from '../../functional/fullDoubleImage';
import Whats from '../../functional/customWhats';
import zero from '../../../assets/wrap_0.jpg';
import uno from '../../../assets/wrap_1.jpg';
import due from '../../../assets/wrap_2.jpg';
import tre from '../../../assets/wrap_3.jpg';

const Desktop =()=> {
  
  
  return (
    
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflow:'hidden'}}>
            <Header text={' WRAPPING'} />
            <video style={{width:'100vw',height:'40vw',objectFit:'cover',margin:'0'}}
             crossOrigin="*"
             autoPlay
             muted
             loop>
              <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rs_wrapping_desk.mp4' type="video/mp4" />

      </video>

      <div className='row' style={{width:'80vw',margin:'3vw 0 0 0',justifyContent:'space-between'}}>
        <div className='column' style={{width:'37vw',alignItems:'flex-start'}}>
            <p style={{fontSize:'1.8vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>RS CUSTOM WRAPPING</p>
            <p style={{fontSize:'1.5vw',margin:'0.5vw 0 0 0',textJustify:'justify'}}>Vuoi cambiare look alla tua vettura, donandole un nuovo colore, un nuovo stile? Il Wrapping grazie a pellicole all’avanguardia ti permette di personalizzare il tuo veicolo.</p>
        </div>
        <div className='column' style={{width:'37vw',alignItems:'flex-start'}}>
            <p style={{fontSize:'1.8vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>PAINT PROTECTION FILM</p>
            <p style={{fontSize:'1.5vw',margin:'0.5vw 0 0 0',textJustify:'justify'}}>Il PPF ovvero il Paint Protection Film, è una pellicola trasparente che protegge la vernice e i fanali da graffi, scheggiature e abrasioni.</p>
        </div>
      </div>
            <Whats svgStyle={{width:'3.5vw',fill:'#25d366',margin:'0 1vw 0 1.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'1.3vw',fontWeight:'700',margin:'0 1.5vw 0 0'}}
             parHeight={'1.3vw'}
             mainMargin={'4vw 0 1vw 0'} />

             
             <FullDoubleImage 
                imageUrl={zero} 
                imgStyle={{width:'40vw',objectFit:'cover',transform:'skew(20deg) rotate(0) translate(4vw,0vw)'}}
                image2Url={due} 
                img2Style={{width:'45vw',objectFit:'cover',objectPosition:'50% 40%',transform:'skew(20deg) translate(1.5vw,-1vw)'}}
                image3Url={tre} 
                img3Style={{width:'50vw',height:'30vw',objectFit:'cover',transform:'skew(20deg)  scale(1) translateX(-5vw)'}}
            />
    
    <div className='row' style={{width:'80vw',justifyContent:'space-between',alignItems:'flex-start'}}>
        <div className='column' style={{width:'37vw',alignItems:'flex-start'}}>
            <p style={{fontSize:'1.8vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>OSCURAMENTO VETRI E FANALI</p>
            <p style={{fontSize:'1.5vw',margin:'0.5vw 0 0 0',textJustify:'justify'}}>Utilizziamo pellicole di alta qualità con la
tecnologia della nano ceramica, che oltre
a dare una maggiore privacy respinge
il calore in modo molto efficace.</p>
        </div>
        <div className='column' style={{width:'37vw',alignItems:'flex-start'}}>
            <p style={{fontSize:'1.8vw',margin:'0',color:'#ff2c02',fontWeight:'600'}}>DETAILING</p>
            <p style={{fontSize:'1.5vw',margin:'0.5vw 0 0 0',textJustify:'justify'}}>Pulizia e cura dell’auto, per migliorarla
esteticamente con le dovute attenzioni
e con i giusti prodotti.
Lucidatura - Decontaminazione - Lavaggio e Asciugatura - Pulizia Tappezzeria</p>
        </div>
      </div>
            
            
            
            
        </div>
            
  
    
  )
}

export default Desktop;

/*

<FullDoubleImage 
                imageUrl={pic} 
                imgStyle={{height:'25vw',objectFit:'cover',transform:'skew(20deg) scale(1) translateX(5vw)'}}
                image2Url={v8} 
                img2Style={{width:'50vw',height:'30vw',objectFit:'cover',objectPosition:'50% 40%',transform:'skew(20deg) translate(-2vw,-2vw)'}}
                image3Url={carrera} 
                img3Style={{width:'50vw',height:'30vw',objectFit:'cover',transform:'skew(20deg)  scale(1) translateX(-2vw)'}}
            />


<RS svgStyle={{width:'25vw',margin:'3vw 0 1vw 0'}} />
                <h2 style={{fontFamily:'Libre Baskerville',fontWeight:'400',fontSize:'1.2vw',color:'#d9d9d9',margin:'0'}}>SUPERCARS &amp; MOTORSPORTS</h2>
                <p style={{fontFamily:'Draco',fontSize:'1.5vw',color:'#ff2c02',letterSpacing:'0.2vw',margin:'3vw 0'}}>sito in costruzione</p>


     const arr = [img0,img1,img2,img3,img4,img5,img6,img7]
  const [i, setI] = useState(0);
  useEffect(()=>{
    const interval = setInterval(() => {
        if (i<arr.length-1){
            setI(i+1);
        }else{
            setI(0);
        }
    }, 600);

    return ()=>{
        clearInterval(interval)
    }
  })
            <img className='midDev' style={{objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 30%'}} src={arr[i]} alt={'glitch'}/>

*/